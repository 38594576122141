import { get, post } from '@/libs/axios'

export const industryInsightPage = params => post('/website/industryInsight/Page', params)
export const legalNewsPage = params => post('/website/legalNews/Page', params)
export const GetIndustryInsightById = params => post('/website/industryInsight/GetIndustryInsightById', params)

export const DeleteIndustryInsightByIds = params => post('/website/industryInsight/DeleteIndustryInsightByIds', params)
export const DeleteLegalNewsByIds = params => post('/website/legalNews/DeleteLegalNewsByIds', params)

export const saveOrUpdateIndustry = params => {
  const submitUrl = params.id ? '/website/industryInsight/UpdateIndustryInsight' : '/website/industryInsight/CreateIndustryInsight'
  return post(submitUrl, params)
}
export const saveOrUpdateLawnews = params => {
  const submitUrl = params.id ? '/website/legalNews/UpdateLegalNews' : '/website/legalNews/CreateLegalNews'
  return post(submitUrl, params)
}

export const uploadImg = params => post('/website/news/upload_img', params)
export const listWebsite = (newsType, params) => post(`/website/news/${newsType}/page`, params)
export const deleteNews = (newsType, params) => post(`/website/news/${newsType}/delete`, params)
export const saveOrUpdateNews = (newsType, params) => {
  const submitUrl = params.id ? `/website/news/${newsType}/update` : `/website/news/${newsType}/save`
  return post(submitUrl, params)
}
export const getNewsInfo = id => get(`/website/news/info?id=${id}`)
